import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import {MDXRenderer} from 'gatsby-plugin-mdx';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import moment from 'moment';
import USAMap from "../USAMap";
import statenames from "../../data/state_abbrev_to_name.json";

import "../../styles/styles.css";
import "../../styles/redistricting-timeline.css";
export const REDISTRICTING_TIMELINE_COLORS = {
    "Severe": "#C62535",
    "High": "#E7785F",
    "Elevated": "#DDC592",
    "Guarded": "#A6DDD1",
    "Low": "#E4EDD4",
    "Map passed": "green"
};

const RedistrictingTimelineAlertMap = () => {
    const contextData = useStaticQuery(graphql`
        query redistrictingTimelineAlertMapQuery {
        allAirtable(filter: {table: {eq: "Timeline Alert"}}) {
            nodes {
                id
                data {
                  TimelineName
                  TimelineState
                  TimelineType
                  State__from_Reforms_
                  Alert
                  Final_Map_Deadline
                  TimelineDeadlineType {
                    id
                    childMdx {
                      body
                    }
                  }
                  TimelineOtherDates {
                    id
                    childMdx {
                      body
                    }
                  }
                  Proposed_Actions
                  TimelineNewsSources {
                    id
                    childMdx {
                      body
                    }
                  }
                  TimelineAdditionalNotes {
                    id
                    childMdx {
                      body
                    }
                  }
                }
              }
            }
        }
    `);

    const [SelectedStateFromMap, setSelectedStateFromMap] = useState(null);
    const [timelineType, setTimelineType] = useState("Congressional");
    const filteredTimeline = contextData.allAirtable.nodes.filter(item => item.data.TimelineType === timelineType);
    const selectedStateData = filteredTimeline.filter(item => item.data.State__from_Reforms_[0] === SelectedStateFromMap);

    return (
        <USAMap
            SelectedStateFromMap={SelectedStateFromMap}
            setSelectedStateFromMap={setSelectedStateFromMap}
            stateData={filteredTimeline}
            dataFilter={(stateDatum, state) => stateDatum.data.State__from_Reforms_[0] === state}
            legend={REDISTRICTING_TIMELINE_COLORS}
            key={"redistricting-timeline-alert"}
            mapKey={"redistricting-timeline-alert"}
            mapTitle={<Link to="/redistricting-timeline-alert-map">Redistricting Timeline Alert Map</Link>}
            mapSubtitle={<>
            The 2020 Census delays affect redistricting processes in every state.
            <b> Click on your state or select your state from the dropdown</b> to learn more about how.
            <br />
            <OutboundLink target="_blank" rel="noopener noreferrer" href="https://us7.campaign-archive.com/?u=1ed63aa66c770d4c2a680227d&id=7a93ee34a4">
                Read more about this map in our newsletter.
            </OutboundLink>
            <br />
            {/* <div>Redistricting type:</div> */}
            <button 
                onClick={() => setTimelineType('Congressional')}
                className={timelineType === 'Congressional' ? 'selected' : ''}
                style={{
                    background: timelineType === 'Congressional' ? "#f58025" : ''
                }}
            >
                Congressional Redistricting
            </button>
            <button 
                onClick={() => setTimelineType('State Legislative')}
                className={timelineType === 'State Legislative' ? 'selected' : ''}
                style={{
                    background: timelineType === 'State Legislative' ? "#f58025" : ''
                }}
            >
                State Legislative Redistricting
            </button>
            {
                selectedStateData[0] && 
                <>
                    <h2>{statenames[selectedStateData[0].data.State__from_Reforms_[0]]}</h2>
                    <table>
                    {selectedStateData[0].data.Final_Map_Deadline &&
                        <tr>
                            <td><strong>Final map deadline</strong></td>
                            <td>{moment(selectedStateData[0].data.Final_Map_Deadline).format("MMM Do YY")}</td>
                        </tr>
                    }
                    {selectedStateData[0].data.TimelineDeadlineType &&
                        <tr>
                            <td><strong>Type of deadline</strong></td>
                            {selectedStateData[0].data.TimelineDeadlineType && selectedStateData[0].data.TimelineDeadlineType.childMdx &&
                                <td><MDXRenderer>{selectedStateData[0].data.TimelineDeadlineType.childMdx.body}</MDXRenderer></td>
                            }
                        </tr>
                    }
                    {selectedStateData[0].data.Proposed_Actions &&
                        <tr>
                            <td><strong>Actions Proposed in State</strong></td>
                            {selectedStateData[0].data.Proposed_Actions &&
                                <td>{selectedStateData[0].data.Proposed_Actions.join(", ")}</td>
                            }
                        </tr>
                    }
                    </table>
                </>
            }
            </>}
            mapLegendTitle={`Alert level`}
            statusFieldName={`Alert`}
        />
    );
};

export default RedistrictingTimelineAlertMap;